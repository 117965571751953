<template>
  <v-row class="d-flex justify-space-between align-end my-2 my-sm-4">
    <v-col cols="7" class="title-drawer">
      <v-icon @click="doSetDrawer" class="icon-color mb-1 mr-0 mr-sm-2">mdi-menu</v-icon>
      {{ titleAppBar }}
    </v-col>
    <v-col cols="5" class="d-flex justify-end align-center pl-0">
      <div>
        <v-badge
          bordered
          color="primary"
          dot
          offset-x="15"
          offset-y="10"
        >
          <v-icon dense class="icon-color px-2">mdi-bell</v-icon>
        </v-badge>
        <v-icon class="icon-color">mdi-power-on</v-icon>
      </div>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs" class="d-flex align-center">
            <h5 class="hidden-xs-only px-2">{{ displayNameUsuario }}</h5>
            <v-avatar
              size="36px"
            >
              <img
                alt="Avatar"
                src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460"
                class="profile-image"
              >
            </v-avatar>
          </div>
        </template>
        <v-card class="options-card" @click="doLogout">
          Cerrar sesión
        </v-card>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AppBar',
  props: {
    atrasBtn: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      title: 'Dashboard'
    }
  },
  computed: {
    ...mapGetters(['drawer', 'titleAppBar']),
    ...mapGetters('perfil', ['displayNameUsuario'])
  },
  methods: {
    ...mapActions(['setDrawer', 'setTitleAppBar', 'logout']),
    ...mapActions('perfil', ['fetchUsuario']),
    doSetDrawer () {
      this.setDrawer(!this.drawer)
    },
    async doLogout () {
      await this.logout()
      await this.$router.push({ name: 'Login' })
    }
  },
  async mounted () {
    this.setTitleAppBar(this.title)
    await this.fetchUsuario()
  }

}
</script>

<style scoped>
.profile-image {
  border: 1px solid grey;
  padding: 2px;
}

.title-drawer {
  font-size: 1.5rem !important;
  font-weight: bold;
  color: #252733;
}

.options-card {
  border: 1px solid #DCDDE7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #000000;
  padding: 7px 33px;

}

@media screen and (max-width: 280px) {
  .title-drawer {
    font-size: 1rem !important
  }
}
</style>
